<template lang="pug">
    .abas
        .head
            ul
                li(v-for="tab in tabs", @click="$emit('changeActive', tab.id)", :class="{ ativo: active == tab.id }", v-html="tab.title") 
        .body
            Dropdown(:contents="tabActive.content" @ativo="")
</template>

<script>
import Dropdown from '@/sections/Unidades/DropDown/DropDown'

export default {
    name: "component-abas",
	props: {
		active: {
			type: [String, null],
			default: null,
		}
	},
    components: {
		Dropdown
	},
    computed: {
        tabs() {
            return this.$store.getters.tabUnits
        },
        tabActive() {
            return this.tabs.find(tab => tab.id == this.active) || {}
        },
    },
}
</script>

<style lang="stylus" scoped src="./Abas.styl"></style>
