<template lang="pug">
    .container
        .top
            .content
                Editor(:contents="contents.content").editor
                Link(:contents="contents.content").link
                    |{{contents.content.name}}
            .mapa
                SvgIcon(data="@svgs/mapa-brasil.svg", original, ref="mapa")
        .bottom
            Abas(:active="active" @changeActive="changeAtivo")
</template>

<script>
import { props } from '@/mixins/component'
import Abas from '@/sections/Unidades/Abas/Abas'
import Editor from '@sections/Pagina/Editor/Editor'
import Link from '@components/Link/Link'

export default {
    name: "component-mapa-brasil",
    props,
    components: {
        Abas,
        Editor,
        Link,
    },
    data() {
        return {
            paths: [],
            tabsIdentifier: 'unidades',
            active: '',
        }
    },
    methods: {
        changeAtivo(id) {
            this.active = id
        }
    },
    watch: {
        active() {   
            this.paths.forEach(path => {
                path.classList.remove('ativo')
                if (path.dataset.uf == this.active)
                    path.classList.add('ativo')
            })
        },
        "$store.getters.tabUnits": function () {
            if (this.$store.getters.tabUnits.length > 0)
                this.changeAtivo(this.$store.getters.tabUnits[0].id)
        }
    },
    created() {
        this.$store.dispatch('fetchUnits')
    },
    mounted() {
        this.paths = this.$refs.mapa.querySelectorAll('path[data-has-coamo]')
        this.paths.forEach(path => {
            if (path.dataset.uf == this.active)
                path.classList.add('ativo')
            path.addEventListener('click', event => {
                this.paths.forEach(item => item.classList.remove('ativo'))
                event.target.classList.add('ativo')
                this.changeAtivo(event.target.dataset.uf)
            })
        })
    },
}
</script>

<style lang="stylus" scoped src="./MapaBrasil.styl"></style>
