import { render, staticRenderFns } from "./MapaBrasil.vue?vue&type=template&id=20ebddfc&scoped=true&lang=pug&"
import script from "./MapaBrasil.vue?vue&type=script&lang=js&"
export * from "./MapaBrasil.vue?vue&type=script&lang=js&"
import style0 from "./MapaBrasil.styl?vue&type=style&index=0&id=20ebddfc&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20ebddfc",
  null
  
)

export default component.exports