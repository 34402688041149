<template lang="pug">
	section.dropdowns
		div.dropdown(:class="{ 'ativo': ativo == unit.id }" v-for="unit, key in contents")
			.titulo(@click="toggleAtivo(unit.id)")
				h4 {{unit.name}}
				FontAwesomeIcon(:icon="icons.faChevronDown")
			.block
				p 
					strong Inicio das atividades: 
					| {{unit.start_activities | startActivities}}
				p
					strong Fone: 
					| {{unit.telephone}}
					a(:href="`https://wa.me/${numberWhats(unit.telephone)}`", target="_blank")
						img(:src="require('@images/whatsapp.png')" v-if="unit.is_whats")
				p
					strong CNPJ: 
					| {{unit.cnpj}}
				p
					strong Inscrição Estadual: 
					| {{unit.cad_icms}}
				p(v-if="unit.number == null")
					strong Endereço: 
					| {{unit.address}}
				p(v-if="unit.number != null")
					strong Endereço: 
					| {{unit.address}}, {{unit.number}}
				p
					strong Município: 
					| {{unit.city}}
				p
					strong Estado: 
					| {{unit.uf}} 
				p
					strong CEP: 
					| {{unit.cep}}
</template>

<script>
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

export default {
    name: "component-drop-down",
	props: {
		contents: {
			type: Array
		}
	},
    data() {
        return {
            ativo: false,
            icons: {
                faChevronDown
            }
        }
    },
	filters: {
		startActivities(value) {
			if(value){
				let date = new Date(value)
				var options = { year: 'numeric', month: 'long', day: 'numeric' };
				return date.toLocaleDateString(`pt-BR`, options)
			}
		}
	},
    methods: {
        toggleAtivo(key) {
			if (this.ativo !== key)
            	this.ativo = key
			else 
				this.ativo = false
			this.$emit('ativo', key)
        },
		numberWhats(telephone) {
			if (telephone == null) return
			return `55${telephone.replace(/\D/g, '')}`
		}
    },
}
</script>

<style lang="stylus" scoped src="./DropDown.styl"></style>
